import { BANNER_DESCRIPTORS } from 'const/index';
import { strings } from 'const/strings';

type BannerMap = () => Record<string, any>;
export const SUB_CATEGORY_BANNER: BannerMap = () => {
  return {
    '1010': [
      {
        icon: BANNER_DESCRIPTORS.TRANSLATE,
        text: strings.BANNER_DESCRIPTORS.EXPERT_MULTILINGUAL_GUIDES,
      },
      {
        icon: BANNER_DESCRIPTORS.STL,
        text: strings.BANNER_DESCRIPTORS.STL,
      },
      {
        icon: BANNER_DESCRIPTORS.SPARKS,
        text: strings.BANNER_DESCRIPTORS.PERSONALIZED,
      },
    ],
    '1026': [
      {
        icon: BANNER_DESCRIPTORS.FnB,
        text: strings.BANNER_DESCRIPTORS.LOCAL_FOOD,
      },
      {
        icon: BANNER_DESCRIPTORS.TRANSLATE,
        text: strings.BANNER_DESCRIPTORS.MULTILINGUAL_GUIDES,
      },
      {
        icon: BANNER_DESCRIPTORS.CULINARY,
        text: strings.BANNER_DESCRIPTORS.CULINARY_EXPERTS,
      },
    ],
    '1108': [
      {
        icon: BANNER_DESCRIPTORS.TICKET,
        text: strings.BANNER_DESCRIPTORS.HASSLE_FREE,
      },
      {
        icon: BANNER_DESCRIPTORS.CLOCK,
        text: strings.BANNER_DESCRIPTORS.FREQUENT_DEPARTURES,
      },
      {
        icon: BANNER_DESCRIPTORS.COFFEE,
        text: strings.BANNER_DESCRIPTORS.ONBOARD_AMENITIES,
      },
    ],
    '1133': [
      {
        icon: BANNER_DESCRIPTORS.TICKET,
        text: strings.BANNER_DESCRIPTORS.HASSLE_FREE,
      },
      {
        icon: BANNER_DESCRIPTORS.CLOCK,
        text: strings.BANNER_DESCRIPTORS.FREQUENT_DEPARTURES,
      },
      {
        icon: BANNER_DESCRIPTORS.COFFEE,
        text: strings.BANNER_DESCRIPTORS.ONBOARD_AMENITIES,
      },
    ],
    '1139': [
      {
        icon: BANNER_DESCRIPTORS.ROUTE,
        text: strings.BANNER_DESCRIPTORS.FLEXI_TRAVEL,
      },
      {
        icon: BANNER_DESCRIPTORS.CANCELLATION,
        text: strings.BANNER_DESCRIPTORS.FLEXI_EXCHANGE_CANCEL,
      },
      {
        icon: BANNER_DESCRIPTORS.DOLLAR,
        text: strings.BANNER_DESCRIPTORS.EXTRA_DISCOUNT,
      },
    ],
    '1023': [
      {
        icon: BANNER_DESCRIPTORS.PAID,
        text: strings.BANNER_DESCRIPTORS.PREPAID,
      },
      {
        icon: BANNER_DESCRIPTORS.CHECK_CIRCLE,
        text: strings.BANNER_DESCRIPTORS.EASY_ACTIVATION,
      },
      {
        icon: BANNER_DESCRIPTORS.GLOBE,
        text: strings.BANNER_DESCRIPTORS.INSTANT_CONNECTIVITY,
      },
    ],
    '1143': [
      {
        icon: BANNER_DESCRIPTORS.DIAMOND,
        text: strings.BANNER_DESCRIPTORS.HANDPICKED,
      },
      {
        icon: BANNER_DESCRIPTORS.PLANNING,
        text: strings.BANNER_DESCRIPTORS.EFFORTLESS,
      },
      {
        icon: BANNER_DESCRIPTORS.INSIGHTS,
        text: strings.BANNER_DESCRIPTORS.EXPERT_GUIDES,
      },
    ],
    '1011': [
      {
        icon: BANNER_DESCRIPTORS.MAP_HEART_PIN,
        text: strings.BANNER_DESCRIPTORS.ALL_TOP_ATTRACTIONS_COVERED,
      },
      {
        icon: BANNER_DESCRIPTORS.CLOCK,
        text: strings.BANNER_DESCRIPTORS.REG_SCHEDULE_UNLIMITED_RIDES,
      },
      {
        icon: BANNER_DESCRIPTORS.TICKET,
        text: strings.BANNER_DESCRIPTORS.LAST_MIN_AVAIL_INSTANT_CONFIRMATION,
      },
    ],
    '1019': [
      {
        icon: BANNER_DESCRIPTORS.EASY_BEST,
        text: strings.DESCRIPTORS.INSTANT_CONFIRMATION,
      },
      {
        icon: BANNER_DESCRIPTORS.INSTANT_MOBILE,
        text: strings.DESCRIPTORS.MOBILE_TICKET,
      },
      {
        icon: BANNER_DESCRIPTORS.SPARKS,
        text: strings.BANNER_DESCRIPTORS.BEST_PRICES,
      },
    ],
    '1132': [
      {
        icon: BANNER_DESCRIPTORS.CHARGING,
        text: strings.BANNER_DESCRIPTORS.CHARGING,
      },
      {
        icon: BANNER_DESCRIPTORS.WIFI,
        text: strings.BANNER_DESCRIPTORS.WIFI,
      },
      {
        icon: BANNER_DESCRIPTORS.FnB,
        text: strings.BANNER_DESCRIPTORS.FnB,
      },
    ],
    '1069': [
      {
        icon: BANNER_DESCRIPTORS.LUXURY,
        text: strings.BANNER_DESCRIPTORS.LUXURY,
      },
      {
        icon: BANNER_DESCRIPTORS.FnB,
        text: strings.BANNER_DESCRIPTORS.FOOD_DRINK,
      },
      {
        icon: BANNER_DESCRIPTORS.PANAROMA,
        text: strings.BANNER_DESCRIPTORS.PANAROMIC,
      },
    ],
    '1061': [
      {
        icon: BANNER_DESCRIPTORS.PANAROMA,
        text: strings.BANNER_DESCRIPTORS.PANAROMIC_3,
      },
      {
        icon: BANNER_DESCRIPTORS.HOURGLASS,
        text: strings.BANNER_DESCRIPTORS.FREQUENT_DEPARTURES,
      },
      {
        icon: BANNER_DESCRIPTORS.AUDIOGUIDE,
        text: strings.BANNER_DESCRIPTORS.MULTI_ROUTES,
      },
    ],
    '1095': [
      {
        icon: BANNER_DESCRIPTORS.PANAROMA,
        text: strings.BANNER_DESCRIPTORS.PANAROMIC_2,
      },
      {
        icon: BANNER_DESCRIPTORS.BEVERAGE,
        text: strings.BANNER_DESCRIPTORS.BEVERAGES,
      },
      {
        icon: BANNER_DESCRIPTORS.SIGHTSEEING,
        text: strings.BANNER_DESCRIPTORS.SIGHTSEEING,
      },
    ],
    '1060': [
      {
        icon: BANNER_DESCRIPTORS.CULINARY,
        text: strings.BANNER_DESCRIPTORS.GOURMET_2,
      },
      {
        icon: BANNER_DESCRIPTORS.NIGHT,
        text: strings.BANNER_DESCRIPTORS.NIGHTTIME,
      },
      {
        icon: BANNER_DESCRIPTORS.HEART,
        text: strings.BANNER_DESCRIPTORS.ROMANTIC,
      },
    ],
    '1094': [
      {
        icon: BANNER_DESCRIPTORS.FnB,
        text: strings.BANNER_DESCRIPTORS.GOURMET,
      },
      {
        icon: BANNER_DESCRIPTORS.BEVERAGE,
        text: strings.BANNER_DESCRIPTORS.BEVERAGES,
      },
      {
        icon: BANNER_DESCRIPTORS.SIGHTSEEING,
        text: strings.BANNER_DESCRIPTORS.SIGHTSEEING,
      },
    ],
    '1058': [
      {
        icon: BANNER_DESCRIPTORS.TRANSFERS,
        text: strings.DESCRIPTORS.TRANSFERS,
      },
      {
        icon: BANNER_DESCRIPTORS.CANCELLATION,
        text: strings.BANNER_DESCRIPTORS.CERTIFIED_OP,
      },
      {
        icon: BANNER_DESCRIPTORS.SIGHTSEEING,
        text: strings.BANNER_DESCRIPTORS.BIRD_EYE,
      },
    ],
    '1049': [
      {
        icon: BANNER_DESCRIPTORS.CANCELLATION,
        text: strings.BANNER_DESCRIPTORS.CERTIFIED_OP,
      },
      {
        icon: BANNER_DESCRIPTORS.SKYDIVING,
        text: strings.BANNER_DESCRIPTORS.MULTI_JUMP,
      },
      {
        icon: BANNER_DESCRIPTORS.ADVENTURE,
        text: strings.BANNER_DESCRIPTORS.EXTREME_ADV,
      },
    ],
    '1057': [
      {
        icon: BANNER_DESCRIPTORS.PANAROMA,
        text: strings.BANNER_DESCRIPTORS.BEST_AERIAL_VIEWS,
      },
      {
        icon: BANNER_DESCRIPTORS.CAMERA,
        text: strings.BANNER_DESCRIPTORS.PHOTO_OP,
      },
      {
        icon: BANNER_DESCRIPTORS.CANCELLATION,
        text: strings.BANNER_DESCRIPTORS.SAFE_CERT_OP,
      },
    ],
    '1012': [
      {
        icon: BANNER_DESCRIPTORS.TRANSLATE,
        text: strings.BANNER_DESCRIPTORS.MULTILINGUAL_GT,
      },
      {
        icon: BANNER_DESCRIPTORS.LANDMARKS,
        text: strings.BANNER_DESCRIPTORS.TOP_ATTRACTIONS_COVERED,
      },
      {
        icon: BANNER_DESCRIPTORS.MAP_PIN,
        text: strings.BANNER_DESCRIPTORS.LOCAL_CULTURE,
      },
    ],
    '1013': [
      {
        icon: BANNER_DESCRIPTORS.SPARKS_NEW,
        text: strings.BANNER_DESCRIPTORS.PERSONALIZED,
      },
      {
        icon: BANNER_DESCRIPTORS.TRANSLATE,
        text: strings.BANNER_DESCRIPTORS.MULTILINGUAL_GT,
      },
      {
        icon: BANNER_DESCRIPTORS.DOLLAR,
        text: strings.BANNER_DESCRIPTORS.EXCLUSIVE_DISC,
      },
    ],
    '1022': [
      {
        icon: BANNER_DESCRIPTORS.PUBLIC_TRANSPORT,
        text: strings.BANNER_DESCRIPTORS.EFFICIENT_PUBLIC_TRANSIT,
      },
      {
        icon: BANNER_DESCRIPTORS.CLOCK,
        text: strings.BANNER_DESCRIPTORS.FREQUENT_SERVICE,
      },
      {
        icon: BANNER_DESCRIPTORS.TICKET,
        text: strings.BANNER_DESCRIPTORS.PREBOOKING,
      },
    ],
    '1017': [
      {
        icon: BANNER_DESCRIPTORS.SPARKS_NEW,
        text: strings.BANNER_DESCRIPTORS.PERSONALIZED,
      },
      {
        icon: BANNER_DESCRIPTORS.MAP_PIN,
        text: strings.BANNER_DESCRIPTORS.LOCAL_CULTURE_IMM,
      },
      {
        icon: BANNER_DESCRIPTORS.SEARCH,
        text: strings.BANNER_DESCRIPTORS.DISCOVER_HIDDEN,
      },
    ],
    '1014': [
      {
        icon: BANNER_DESCRIPTORS.CANCELLATION,
        text: strings.BANNER_DESCRIPTORS.CERTIFIED_OP,
      },
      {
        icon: BANNER_DESCRIPTORS.SIGHTSEEING,
        text: strings.BANNER_DESCRIPTORS.CITY_SIGHTSEEING,
      },
      {
        icon: BANNER_DESCRIPTORS.ADVENTURE,
        text: strings.BANNER_DESCRIPTORS.OFFBEAT_ADV,
      },
    ],
    '1030': [
      {
        icon: BANNER_DESCRIPTORS.BEVERAGE,
        text: strings.BANNER_DESCRIPTORS.DRINK_DEALS,
      },
      {
        icon: BANNER_DESCRIPTORS.MAP_PIN,
        text: strings.BANNER_DESCRIPTORS.LOCAL_CULTURE_IMM,
      },
      {
        icon: BANNER_DESCRIPTORS.SPARKS_NEW,
        text: strings.BANNER_DESCRIPTORS.OFFBEAT_EXP,
      },
    ],
    '1009': [
      {
        icon: BANNER_DESCRIPTORS.TRANSLATE,
        text: strings.BANNER_DESCRIPTORS.EXPERT_MULTILINGUAL_GUIDES,
      },
      {
        icon: BANNER_DESCRIPTORS.LANDMARKS,
        text: strings.BANNER_DESCRIPTORS.HIDDEN_GEMS,
      },
      {
        icon: BANNER_DESCRIPTORS.MAP_PIN,
        text: strings.BANNER_DESCRIPTORS.EXPLORE_LOCAL,
      },
    ],
    '1008': [
      {
        icon: BANNER_DESCRIPTORS.ONE_CARD,
        text: strings.BANNER_DESCRIPTORS.ALL_ATTR_ONE_CARD,
      },
      {
        icon: BANNER_DESCRIPTORS.MULTI_OP,
        text: strings.BANNER_DESCRIPTORS.MULTI_CARD,
      },
      {
        icon: BANNER_DESCRIPTORS.COST_EFF,
        text: strings.BANNER_DESCRIPTORS.COST_EFF,
      },
    ],
    '1074': [
      {
        icon: BANNER_DESCRIPTORS.PAINT,
        text: strings.BANNER_DESCRIPTORS.CUSTOMIZED_TREATMENTS,
      },
      {
        icon: BANNER_DESCRIPTORS.CANCELLATION,
        text: strings.BANNER_DESCRIPTORS.TRAINED_THERAPISTS,
      },
      {
        icon: BANNER_DESCRIPTORS.LOTUS,
        text: strings.BANNER_DESCRIPTORS.HOLISTIC_WELLNESS,
      },
    ],
    '1067': [
      {
        icon: BANNER_DESCRIPTORS.HOURGLASS,
        text: strings.BANNER_DESCRIPTORS.FLEXIBLE_DURATION,
      },
      {
        icon: BANNER_DESCRIPTORS.SPEED_BOAT,
        text: strings.BANNER_DESCRIPTORS.MULTI_BOAT,
      },
      {
        icon: BANNER_DESCRIPTORS.ROUTE,
        text: strings.BANNER_DESCRIPTORS.SCENIC_ROUTES,
      },
    ],
    '1027': [
      {
        icon: BANNER_DESCRIPTORS.PAN,
        text: strings.BANNER_DESCRIPTORS.HANDS_ON,
      },
      {
        icon: BANNER_DESCRIPTORS.CULINARY,
        text: strings.BANNER_DESCRIPTORS.CULTURAL_CULINARY,
      },
      {
        icon: BANNER_DESCRIPTORS.TICK,
        text: strings.BANNER_DESCRIPTORS.EXPERT_HOSTS,
      },
    ],
    '1101': [
      {
        icon: BANNER_DESCRIPTORS.ADVENTURE,
        text: strings.BANNER_DESCRIPTORS.ADVENTURE_EXPERIENCE,
      },
      {
        icon: BANNER_DESCRIPTORS.CANCELLATION,
        text: strings.BANNER_DESCRIPTORS.CERTIFIED_OP,
      },
      {
        icon: BANNER_DESCRIPTORS.SNORKELLING,
        text: strings.BANNER_DESCRIPTORS.EQUIPMENT,
      },
    ],
    '1059': [
      {
        icon: BANNER_DESCRIPTORS.SPARKS_NEW,
        text: strings.BANNER_DESCRIPTORS.PERSONALIZED,
      },
      {
        icon: BANNER_DESCRIPTORS.TRANSLATE,
        text: strings.BANNER_DESCRIPTORS.MULTILINGUAL_GT,
      },
      {
        icon: BANNER_DESCRIPTORS.DOLLAR,
        text: strings.BANNER_DESCRIPTORS.EXCLUSIVE_DISC,
      },
    ],
  };
};

export const CATEGORY_BANNER: BannerMap = () => {
  return {
    '18': [
      {
        icon: BANNER_DESCRIPTORS.PANAROMA,
        text: strings.BANNER_DESCRIPTORS.PANAROMIC_2,
      },
      {
        icon: BANNER_DESCRIPTORS.HOURGLASS,
        text: strings.BANNER_DESCRIPTORS.FREQUENT_DEPARTURES,
      },
      {
        icon: BANNER_DESCRIPTORS.ONBOARD_MEAL,
        text: strings.BANNER_DESCRIPTORS.SIGHTSEEING_MEAL,
      },
    ],
    '6': [
      {
        icon: BANNER_DESCRIPTORS.DIAMOND,
        text: strings.BANNER_DESCRIPTORS.HANDPICKED,
      },
      {
        icon: BANNER_DESCRIPTORS.PLANNING,
        text: strings.BANNER_DESCRIPTORS.EFFORTLESS,
      },
      {
        icon: BANNER_DESCRIPTORS.INSIGHTS,
        text: strings.BANNER_DESCRIPTORS.EXPERT_GUIDES,
      },
    ],
  };
};
